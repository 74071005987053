import WaypointComponent from '../../components/WaypointComponent';

const AboutSection = () => {
  return (
    <section id="about">
      <div className="container flex">
        <WaypointComponent className="header waypoint" animate="slide-in-left">
          ABOUT
        </WaypointComponent>
        <WaypointComponent className="header-bar waypoint" animate="slide-in-left"></WaypointComponent>
        <div className="flex row label-wrap">
          <div className="flex row-gt-sm">
            <div className="flex bullet-wrap">
              <WaypointComponent className="hex-wrap waypoint" animate="flip-in-x">
                <div className="hexagon">
                  <i className="mdi mdi-speedometer"></i>
                </div>
              </WaypointComponent>
              <WaypointComponent className="waypoint" animate="fade-in">
                <div className="label bold">Fast</div>
                <div>Fast load times and lag free interaction is my highest priority.</div>
              </WaypointComponent>
            </div>

            <div className="flex bullet-wrap">
              <WaypointComponent className="hex-wrap waypoint" animate="flip-in-x">
                <div className="hexagon">
                  <i className="mdi mdi-cellphone-link"></i>
                </div>
              </WaypointComponent>
              <WaypointComponent className="waypoint" animate="fade-in">
                <div className="label bold">Responsive</div>
                <div>My layouts will work on any device, big or small.</div>
              </WaypointComponent>
            </div>
          </div>
          <div className="flex row-gt-sm">
            <div className="flex bullet-wrap ">
              <WaypointComponent className="hex-wrap waypoint" animate="flip-in-x">
                <div className="hexagon">
                  <i className="mdi mdi-lightbulb-outline"></i>
                </div>
              </WaypointComponent>
              <WaypointComponent className="waypoint" animate="fade-in">
                <div className="label bold">Intuitive</div>
                <div>Strong preference for easy to use, intuitive UX/UI.</div>
              </WaypointComponent>
            </div>

            <div className="flex bullet-wrap ">
              <WaypointComponent className="hex-wrap waypoint" animate="flip-in-x">
                <div className="hexagon">
                  <i className="mdi mdi-rocket"></i>
                </div>
              </WaypointComponent>
              <WaypointComponent className="waypoint" animate="fade-in">
                <div className="label bold">Dynamic</div>
                <div>Websites don't have to be static, I love making pages come to life.</div>
              </WaypointComponent>
            </div>
          </div>
        </div>

        <div className="skills-wrapper flex row-gt-sm">
          <WaypointComponent className="flex flex-50-gt-sm waypoint" animate="slide-in-left">
            <img src="./img/me.jpg" className="me" alt="Fordyce Gozali" />
            <div className="label bold">Who's this guy?</div>
            <div>
              I'm a Front-End Developer in Medan, Indonesia
              <br />
              I have serious passion for UI/UX and creating intuitive, dynamic user experiences.
              <br />
              <a className="page-link highlight" href="#contact">Let's make something special.</a>
            </div>
          </WaypointComponent>

          <WaypointComponent className="flex flex-50-gt-sm waypoint bars-wrap" animate="slide-in-right">
            <div className="bar flex">
              <div className="tag bold flex">Laravel / PHP</div>
              <span>Very Familiar</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">Wordpress</div>
              <span>Very Familiar</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">React / NextJS</div>
              <span>Very Familiar</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">AWS / Cloud</div>
              <span>Strong Understanding</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">Python</div>
              <span>Good</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">Kafka / Rabbit</div>
              <span>Good</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">.NET / C#</div>
              <span>Familiar</span>
            </div>
            <div className="bar flex">
              <div className="tag bold flex">Mobile Dev</div>
              <span>Good with React Native / Flutter</span>
            </div>
          </WaypointComponent>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
