import { Outlet } from 'react-router-dom';
import ProjectModal from '../components/ProjectModal';
import projectStore from '../store/projectStore';

const Layout = () => {
  const { project, setProject }: any = projectStore();

  return (
    <div className="overflow-wrap">
      {project !== null && (
        <ProjectModal project={project} onClose={() => setProject(null)} />
      )}
      <Outlet />
    </div>
  );
};

export default Layout;
