import Home from '../pages/Home';
import Project from '../pages/Project';
import NotFound from '../pages/NotFound';

let views: any = {
  Home,
  Project,
  NotFound
};

export default views;
