import { useEffect } from 'react';
import WaypointComponent from '../../components/WaypointComponent';
import { projects } from './project';
import projectStore from '../../store/projectStore';

const ProjectSection = () => {
  const { setProject, clearProject }: any = projectStore();

  useEffect(() => {
    clearProject();
  }, []);

  return (
    <>
      <section className="flex" id="portfolio">
        <WaypointComponent className="header waypoint" animate="slide-in-right">
          PROJECTS
        </WaypointComponent>
        <WaypointComponent className="header-bar waypoint" animate="slide-in-right"></WaypointComponent>
        <h3>Tap/click on each box to learn more</h3>
        <div id="gallery" className="container flex row wrap animated">
          {projects.map((p: any, index: number) => {
            return (
              <div className="mix react" key={`project-${index}`}>
                <div>
                  <div className="card" style={{ backgroundImage: `url('${p.cover}')` }}></div>
                  <div className="text">
                    <div className="bold">{p.title}</div>
                    <span className="highlight">{p.subtitle}</span>
                  </div>
                  <div className="button" onClick={() => setProject(p)}>
                    LEARN MORE
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ProjectSection;
