import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const isMobile = () => {
  return window.innerWidth <= 768;
};

const NavigationBar = () => {
  const [navClassName, setNavClassName] = useState<string>('');
  const [offset, setOffset] = useState(0);
  const [lastPos, setLastPos] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setNavClassName(isMobile() ? 'fixed' : 'desk');
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!isMobile() && ref !== null && ref.current !== null) {
      const navPos = ref.current.getBoundingClientRect().top;
      if (offset >= navPos + ref.current.offsetHeight && lastPos < offset) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
      setLastPos(navPos);
    }
  }, [offset]);

  return (
    <nav ref={ref} className={`flex ${navClassName} ${isFixed ? 'fixed' : ''}`}>
      <div className={`link-wrap ${isMobileVisible && isMobile() && 'visible'}`}>
        <div className="page-link">
          <Link to="/">home</Link>
        </div>
        <div className="page-link">
          <a href="#about">about</a>
        </div>
        <div className="page-link">
          <a href="#portfolio">portfolio</a>
        </div>
        <div className="page-link">
          <a href="#experience">work experience</a>
        </div>
        <div className="page-link">
          <a href="#contact">contact</a>
        </div>
      </div>
      <i className="mdi mdi-menu" onClick={() => setIsMobileVisible(!isMobileVisible)}></i>
    </nav>
  );
};

export default NavigationBar;
