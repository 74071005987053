import { useEffect, useState, useRef } from 'react';

const ProjectModal = (props: any) => {
  const { project, onClose } = props;
  const [isMoving, setIsMoving] = useState(false);
  const [slideWidth, setSlideWidth] = useState(700);
  const ref = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<any>([]);
  const [previousDirection, setPreviousDirection] = useState<number>(0);

  const setDimensions = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setSlideWidth(window.innerWidth);
    }
  };

  const shiftSlide = (direction: number) => {
    if (isMoving) return;
    if (ref === null || ref.current === null) return;
    setIsMoving(true);
    ref.current.classList.add('transition');
    ref.current.style.transform = `translateX(${direction * slideWidth}px)`;

    setTimeout(() => {
      if (ref === null || ref.current === null) return;
      const carousel = ref.current;
      let clonedImages: any = [...images];
      if (previousDirection !== direction) {
        clonedImages.reverse();
        setPreviousDirection(direction);
        setImages(clonedImages);
      } else {
        const newArr = clonedImages.concat(clonedImages.shift());
        setPreviousDirection(direction);
        setImages(newArr);
      }
      setIsMoving(false);
      carousel.style.transform = 'translateX(0px)';
    }, 500);
  };

  useEffect(() => {
    setDimensions();
    setImages(project.images);
  }, []);

  return (
    <>
      {project !== null && (
        <div className="modal-wrap flex visible">
          <div className="mask"></div>
          <div className="modal" id="modal" style={{ maxWidth: `${slideWidth}px` }}>
            <div className="carousel-wrap" style={{ width: `${slideWidth}px` }}>
              <div className="window">
                <div
                  id="carousel"
                  className={isMoving ? 'transition' : ''}
                  ref={ref}
                  style={{ left: `${slideWidth * -1}px` }}
                >
                  {images.map((image: string, index: number) => {
                    return (
                      <div
                        key={`slide-${index}`}
                        className="slide"
                        style={{
                          width: `${slideWidth}px`,
                          background: `url("${image}") center center / cover`,
                        }}
                      ></div>
                    );
                  })}
                </div>
                <i className="mdi mdi-chevron-left" id="prev" onClick={() => shiftSlide(-1)}></i>
                <i className="mdi mdi-chevron-right" id="next" onClick={() => shiftSlide(1)}></i>
              </div>
            </div>
            <div className="info-box">
              <div className="title">{project.title}</div>
              <div className="tag">{project.subtitle}</div>
              <div className="detail">{project.description}</div>
              {project.website !== undefined && (
                <a href={project.website} target="_blank" rel="noreferrer">
                  <div className="button visible">
                    <i className="mdi mdi-open-in-new"></i>VIEW SITE
                  </div>
                </a>
              )}
            </div>
            <i className="close mdi mdi-close" onClick={() => onClose()}></i>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectModal;
