export const projects = [
 {
  title: "Wiliam Edison Coffee",
  subtitle: "Laravel / React",
  description: "Coffee Roastery Product Catalog with CMS",
  cover: "./img/wiliamedison/1.png",
  website: "https://wiliamedison.coffee/",
  images: [
    "./img/wiliamedison/1.png",
    "./img/wiliamedison/2.png",
    "./img/wiliamedison/3.png"
  ]
},
{
  title: "SAP - Usdama",
  subtitle: "Laravel / PHP",
  description: "For a rice company, tracking inventory, sales, purchase, debts for each warehouse",
  cover: "./img/usdama/1.png",
  images: [
    "./img/usdama/1.png",
    "./img/usdama/2.png",
    "./img/usdama/3.png",
  ]
},
{
  title: "Security for Estate",
  subtitle: "ReactJS / Laravel",
  description: "System for a complex housing to record guest coming in and out, with auto emailing report once a month or by request directly from web",
  cover: "./img/polonia/1.png",
  images: [
    "./img/polonia/1.png",
    "./img/polonia/2.png",
    "./img/polonia/3.png",
  ]
},
{
  title: "Moovaz",
  subtitle: "PHP / Python / Typescript / Kafka",
  description: "Zapier automation to Hubspot CRM, using python to filter email data and set the lead owners. Also has wordpress to gather leads.",
  cover: "./img/moovaz/3.png",
  website: "https://www.moovaz.com",
  images: [
    "./img/moovaz/1.png",
    "./img/moovaz/2.png",
    "./img/moovaz/3.png",
    "./img/moovaz/4.png",
  ]
},
{
  title: "SAP - IoStok",
  subtitle: "Laravel / Xero",
  description: "Tracking inventory for tire company with racks on each warehouse. Include sales, purchasing, travel document to pdf, invoice generation to XERO.",
  cover: "./img/iostok/1.png",
  images: [
    "./img/iostok/1.png",
    "./img/iostok/2.png",
    "./img/iostok/3.png",
    "./img/iostok/4.png",
  ]
},
{
  title: "Barbershop - Brooklyn",
  subtitle: "Laravel",
  description: "System for barbershop to track customer, their bonuses, KPI for the barbers, transaction report, for multiple branches. Optimized for tablet and mobile usage.",
  cover: "./img/brooklyn/1.png",
  images: [
    "./img/brooklyn/1.png",
    "./img/brooklyn/2.png",
    "./img/brooklyn/3.png",
    "./img/brooklyn/4.png",
  ]
},
{
  title: "Media - IDNPost",
  subtitle: "Laravel / React / Kafka",
  description: "News portal with reactjs, complete with CMS and tracking author's KPI. Website is on https://idnpost.com",
  cover: "./img/idnpost/1.png",
  website: "https://www.idnpost.com",
  images: [
    "./img/idnpost/1.png",
    "./img/idnpost/2.png",
    "./img/idnpost/3.png",
    "./img/idnpost/4.png",
  ]
},
{
  title: "SAP for Common Usage",
  subtitle: "Laravel / React / Xero",
  description: "Inventory system with Laravel + reactJS with complicated sales/purchasing system. Works for multiple warehouses, have complete user CRUD permissions for each modules Integrated with XERO for invoice generation",
  cover: "./img/sap/1.png",
  images: [
    "./img/sap/1.png",
    "./img/sap/2.png",
    "./img/sap/3.png",
    "./img/sap/4.png",
  ]
},
{
  title: "Indozone",
  subtitle: "Laravel / Redis",
  description: "News Portal",
  cover: "./img/indozone/1.png",
  website: "https://www.indozone.id",
  images: [
    "./img/indozone/1.png",
    "./img/indozone/2.png",
    "./img/indozone/3.png",
  ]
},
{
  title: "101 Chinese Education Centre",
  subtitle: "Laravel / Redis",
  description: "Education Portal Website, with backend system to track teachers attendance, payroll, and student progress",
  cover: "./img/101/1.png",
  website: "https://101chineseeducenter.com",
  images: [
    "./img/101/1.png",
    "./img/101/2.png",
    "./img/101/3.png",
    "./img/101/4.png",
    "./img/101/5.png",
    "./img/101/6.png",
  ]
},
];