import { useState, useEffect } from 'react';
import { useInViewport } from '../hooks/useInViewport';

const WaypointComponent = ({ className, animate, children }: any) => {
  const { isInViewport, ref } = useInViewport();
  const [componentClassName, setComponentClassName] = useState<string>(className);

  useEffect(() => {
    if (isInViewport) {
      setComponentClassName((c: string) => {
        return `${c} animated ${animate}`;
      });
    }
  }, [isInViewport]);

  return (
    <div ref={ref} className={componentClassName}>
      {children}
    </div>
  );
};

export default WaypointComponent;
