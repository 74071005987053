import WaypointComponent from '../../components/WaypointComponent';

const ContactSection = () => {
  return (
    <section id="contact">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 100 102"
        height="75"
        width="100%"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="svgcolor-light"
      >
        <path d="M0 0 L50 100 L100 0 Z" fill="white" stroke="white"></path>
      </svg>
      <div className="container flex">
        <WaypointComponent className="header waypoint" animate="slide-in-left">
          CONTACT
        </WaypointComponent>
        <WaypointComponent className="header-bar waypoint" animate="slide-in-right"></WaypointComponent>
        <WaypointComponent className="highlight waypoint" animate="slide-in-right">
          Have a question or want to work together?
        </WaypointComponent>
        <p>
          Contact me at <span className="highlight">forddyce92@gmail.com</span>, or my number{' '}
          <a href="https://api.whatsapp.com/send?phone=6281289520078" rel="noreferrer" target="_blank" className="highlight">+62 812 8952 0078 (WhatsApp)</a>
        </p>
      </div>
    </section>
  );
};

export default ContactSection;
