import Layout from '../layout/Layout';
import views from './views';

const routes = () => {
  return [
    {
      path: '/',
      element: <Layout />,
      errorElement: <views.NotFound />,
      children: [
        {
          path: '/',
          element: <views.Home />,
        },
        {
          path: '/portfolio',
          element: <views.Project />,
        },
        {
          path: '*',
          element: <views.NotFound />,
        }
      ],
    },
  ];
}

export default routes;
