import WaypointComponent from '../../components/WaypointComponent';

const ExperienceSection = () => {
  return (
    <section id="experience" className="flex">
      <WaypointComponent className="header waypoint" animate="slide-in-left">
        Past Work Experience
      </WaypointComponent>
      <WaypointComponent className="header-bar waypoint" animate="slide-in-left"></WaypointComponent>
      <ol className="exp-list">
        <li>
          <WaypointComponent className="waypoint" animate="slide-in-left">
            <h2 className="highlight">Contract Frontend Developer at Accenture (2022-2023)</h2>
            <ul>
              <li>Helped creating foundation for Frontend Stack together with Team Lead</li>
              <li>Created pixel perfect app with 90% accuracy to confluence documents</li>
              <li>Review PR requests in Jira and worked alongside 20+ developers worldwide</li>
            </ul>
          </WaypointComponent>
        </li>

        <li>
          <WaypointComponent className="waypoint" animate="slide-in-left">
            <h2 className="highlight">IT Support &amp; Systems Architect at Moovaz (2020-2022)</h2>
            <ul>
              <li>Work alongside Singaporean Product Team to design the application</li>
              <li>Creating automation with zapier to hubspot CRM</li>
              <li>Provide consultation to business solution in technical terms</li>
              <li>Maintain and improve company website, tools</li>
            </ul>
          </WaypointComponent>
        </li>

        <li>
          <WaypointComponent className="waypoint" animate="slide-in-left">
            <h2 className="highlight">IT Lead at Indozone (2018-2020)</h2>
            <ul>
              <li>Building the foundation of the web system</li>
              <li>Lead a team of FE, BE, UI/UX, and work alongside SEO and Product Team</li>
              <li>Helped moving the app from dedicated to cloud (AWS)</li>
              <li>
                Worked alongside shareholder and founder and provide technical consultation for products they
                desire
              </li>
            </ul>
          </WaypointComponent>
        </li>
      </ol>
    </section>
  );
};

export default ExperienceSection;
