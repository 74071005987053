import { Suspense } from 'react';
import routes from './config/routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import "./App.scss";
import ReactGA from "react-ga4";

const App = () => {
  ReactGA.initialize("G-E63YDMWN73");
  const router = createBrowserRouter(routes());

  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
