import { useEffect } from 'react';
import NavigationBar from '../components/NavigationBar';
import AboutSection from './modules/AboutSection';
import ProjectSection from './modules/ProjectSection';
import ExperienceSection from './modules/ExperienceSection';
import ContactSection from './modules/ContactSection';

const Home = () => {
  const floatySpace = () => {
    var space;
    var colors = ['#FF3F8E', '#04C2C9', '#2E55C1'];
    // @ts-ignore
    space = new CanvasSpace('canvas', '#252934').display();
    // @ts-ignore
    var form = new Form(space);

    // @ts-ignore
    var pts: any[] = [];
    var center = space.size.$divide(1.8);
    var angle = -(window.innerWidth * 0.5);
    var count = window.innerWidth * 0.05;
    if (count > 150) count = 150;
    // @ts-ignore
    var line = new Line(0, angle).to(space.size.x, 0);
    var mouse = center.clone();

    var r = Math.min(space.size.x, space.size.y) * 1;
    for (var i = 0; i < count; i++) {
      // @ts-ignore
      var p = new Vector(Math.random() * r - Math.random() * r, Math.random() * r - Math.random() * r);
      p.moveBy(center).rotate2D((i * Math.PI) / count, center);
      p.brightness = 0.1;
      pts.push(p);
    }

    space.add({
      animate: () => {
        for (var i = 0; i < pts.length; i++) {
          // @ts-ignore
          var pt = pts[i];

          // @ts-ignore
          pt.rotate2D(Const.one_degree / 20, center);
          form
            .stroke(false)
            .fill(colors[i % 3])
            .point(pt, 1);

          // @ts-ignore
          var ln = new Line(pt).to(line.getPerpendicularFromPoint(pt));
          var distFromMouse = Math.abs(ln.getDistanceFromPoint(mouse));

          if (distFromMouse < 50) {
            if (pts[i].brightness < 0.3) pts[i].brightness += 0.015;
          } else {
            if (pts[i].brightness > 0.1) pts[i].brightness -= 0.01;
          }

          var color = 'rgba(255,255,255,' + pts[i].brightness + ')';
          form.stroke(color).fill(true).line(ln);
        }
      },
      // @ts-ignore
      onMouseAction: function (type, x, y, evt) {
        if (type === 'move') {
          mouse.set(x, y);
        }
      },
      // @ts-ignore
      onTouchAction: function (type, x, y, evt) {
        this.onMouseAction(type, x, y);
      },
    });

    space.bindMouse();
    space.play();
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = './pt.min.js';
    script.async = true;
    script.onload = floatySpace;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <section id="home" className="flex height-fix">
        <div id="pt" className="canvas"></div>
        <div className="flex">
          <div className="text">
            Hello, I'm <span className="highlight">Fordyce Gozali</span>.
            <br />
            I'm a front end web developer. I also do full stack.
          </div>
          <a className="button page-link" href="#portfolio">
            View my work <i className="mdi mdi-arrow-right"></i>
          </a>
          <NavigationBar />
        </div>
      </section>
      <AboutSection />
      <ProjectSection />
      <ExperienceSection />
      <ContactSection />
    </>
  );
};

export default Home;
