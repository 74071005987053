import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const projectStore = create(
  persist((set) => ({
    project: null,
    setProject: (params: any) => {
      set(() => ({
        project: params,
      }));
    },
    clearProject: () => {
      set(() => ({
        project: null,
      }));
    },
  }),
  { name: 'project' }
  )
);

export default projectStore;
